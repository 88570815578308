import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip, Spinner, Form } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Card from '../../../components/Card';
import AddModal from './add-movie';
import { apiCall } from "../../../utils/apiWrapper";
import Skeleton from 'react-loading-skeleton';
import CustomPagination from '../../../components/CustomPagination';
import 'react-loading-skeleton/dist/skeleton.css';


const MovieList = () => {
    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    const [videoLoading, setVideoLoading] = useState('');
    const [addModalShow, setAddModalShow] = useState(false);
    const [form, setForm] = useState({});
    const [editData, setEditData] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const getVideoList = () => {
        apiCall(`video/pagination/10/${activePage}`, 'get')
            .then((res) => {
                console.log("getVideoList >> ",res);
                if (res.status === 200) {
                    setTotalItems(res?.data?.totalCount);
                    setUploadedVideoList(res?.data?.data);
                }
            })
    };

    const updateVideo = (videoId, data) => {
        setVideoLoading(videoId);
        apiCall(`video/${videoId}`, 'put', data)
            .then((res) => {
                setVideoLoading('');
                if (res.status === 200) {
                    getVideoList();
                }
            })
    };

    const onChangeVideoStatus = (videoId, checked) => {
        let newData = uploadedVideoList.map(data => {
            if (data?._id === videoId) {
                data.showOnHomeScreen = checked;
            }
            return data;
        });
        setUploadedVideoList(newData);
        apiCall(`video/${videoId}`, 'put', { showOnHomeScreen: checked })
            .then((res) => {
                if (res.status === 200) {

                }
            })
    };

    const onClickEdit = (data) => {
        setAddModalShow(true);
        setEditData(data);
    };

    useEffect(() => {
        if (addModalShow) {
            return;
        }
        setEditData('');
    }, [addModalShow]);

    useEffect(() => {
        console.log("editData >> ",editData);
        if (editData) {
            setForm({ ...editData, category: editData?.categories });
        }
    }, [editData]);

    useEffect(() => {
        getVideoList();
    }, [activePage]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Header.Title>
                                    <h4 className="card-title">Music Lists</h4>
                                </Card.Header.Title>
                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <span onClick={() => setAddModalShow(true)} className="btn btn-primary">Add Music</span>
                                </div>
                            </Card.Header>
                            <Card.Body>

                                {(uploadedVideoList.length > 0) ? (
                                    <div className="table-view">
                                        <table className="data-tables table movie_table " style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "40%" }}>Music</th>
                                                    <th>Category</th>
                                                    <th>Sub Category</th>
                                                    {/* <th>Release Year</th> */}
                                                    <th style={{ width: "20%" }}>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uploadedVideoList?.map((data, i) =>
                                                    <tr style={{ backgroundColor: data?.showOnHomeScreen ? '#5F2255' : '#181142' }}>
                                                        <td>
                                                            <div className="media align-items-center">
                                                                <div className="iq-movie">
                                                                    <Link to="#">
                                                                        <img src={data?.thumbnail} className="img-border-radius avatar-40 img-fluid" alt="" />
                                                                    </Link>
                                                                </div>
                                                                <div className="media-body text-white text-left ml-3">
                                                                    <p className="mb-0">{data?.title}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* <td>{moment.utc(parseInt(data?.duration * 1000)).format("HH:mm:ss")}</td> */}
                                                        <td>{data?.categories?.categoryName}</td>
                                                        <td>{data?.subcategories?.SubcategoryName}</td>
                                                        {/* <td>{new Date(data?.createdAt).toLocaleDateString()}</td> */}
                                                        <td>
                                                            <p className="m-0">
                                                                {data?.description}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="flex align-items-center list-user-action">
                                                                {/* <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                                                                    <Link className="iq-bg-warning" to="#"><i className="lar la-eye"></i></Link>
                                                                </OverlayTrigger> */}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                                    <Link onClick={() => onClickEdit(data)} className="iq-bg-success" to="#"><i className="ri-pencil-line"></i></Link>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                    {(videoLoading === data?._id) ? (
                                                                        <Spinner size="sm" animation="border" />
                                                                    ) : (
                                                                        <Link onClick={() => updateVideo(data?._id, { isVisible: false })} className="iq-bg-primary" to="#"><i className="ri-delete-bin-line"></i></Link>
                                                                    )}
                                                                </OverlayTrigger>
                                                                {/* <OverlayTrigger placement="top" overlay={<Tooltip>Mark video for home screen</Tooltip>}>
                                                                    <div className="custom-control custom-switch">
                                                                        <Form.Check.Input onClick={(e) => onChangeVideoStatus(data?._id, e.target.checked)} checked={data?.showOnHomeScreen} type="checkbox" className="custom-control-input" id={data?._id} />
                                                                        <Form.Check.Label className="custom-control-label" htmlFor={data?._id}></Form.Check.Label>
                                                                    </div>
                                                                </OverlayTrigger> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <Row className="justify-content-center">
                                            <CustomPagination activePage={activePage} setActivePage={setActivePage} totalItems={totalItems} />
                                        </Row>
                                    </div>
                                ) : (
                                    <div>
                                        {["", "", "", "", "", "", "", ""].map(() => <Skeleton baseColor="#181142" highlightColor="#241B57" className="mt-4" height={64} />)}
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <AddModal form={form} setForm={setForm} setAddModalShow={setAddModalShow} addModalShow={addModalShow} getVideoList={getVideoList} editData={editData} />
            </Container>
        </>
    )
}
export default MovieList;
import React, { useEffect } from 'react';
import LayoutsRoute from './router/layouts-route';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SignIn from './views/backend/auth/signin';

import AddCategory from './views/backend/category/add-category';
import CategoryList from './views/backend/category/category-list';
// import addSubCategory from './views/backend/subcategory/add-subcategory';
import SubCategoryList from './views/backend/subcategory/subcategory-list';
import AddTag from './views/backend/tag/add-tag';
import TagList from './views/backend/tag/tag-list';
import AddMovie from './views/backend/movie/add-movie';
import MovieList from './views/backend/movie/movie-list';
import LiveList from './views/backend/live/live-list';
import RecordingList from './views/backend/recordings/recording-list';
import TrimmedList from './views/backend/trimmed-video/trimmed-video-list';
import Dashbord from './views/backend/main/dashbord';
import Marque from './views/backend/marque/marque-list';
// Partials
import HeaderStyle1 from './components/partials/backend/headerstyle/headerstyle1';
import SidebarStyle from './components/partials/backend/sidebarstyle/sidebarstyle';
import FooterStyle from './components/partials/backend/footerstyle/footerstyle';

import AppLayout from './layouts/appLayout';

//scss files
import './assets/css/bootstrap.min.css';
import './assets/css/typography.css';
import './assets/css/style.css';
import './assets/css/responsive.css';


// import  './assets/css/custom.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth/sign-in" element={<SignIn />} />
      </Routes>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" exact element={<Dashbord />} />
          {/* <Route path="/add-category" element={<AddCategory />} /> */}
          <Route path="/category" element={<CategoryList />} />
          <Route path="/subcategory" element={<SubCategoryList />} />
          {/* <Route path="/add-tag" element={<AddTag />} /> */}
          <Route path="/tag" element={<TagList />} />
          {/* <Route path="/add-video" element={<AddMovie />} /> */}
          <Route path="/video" element={<MovieList />} />
          <Route path="/stream" element={<LiveList />} />
          <Route path="/recording-list" element={<RecordingList />} />
          <Route path="/trimmed-video" element={<TrimmedList />} />
          {/* <Route path="/banner-text" element={<Marque />} /> */}
        </Route>
      </Routes>
    </div >
  );
}

export default App;

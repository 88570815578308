import React, { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";

const CustomPagination = ({ activePage, setActivePage, totalItems }) => {

    const handlePageClick = (event) => {

    };

    return (
        <React.Fragment>
            {/* <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item"><span className="page-link pagination-button" href="#">Previous</span></li>
                    {printButtons(15)}
                    <li className="page-item"><span className="page-link pagination-button" href="#">Next</span></li>
                </ul>
            </nav> */}
            <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={totalItems}
                pageRangeDisplayed={10}
                onChange={setActivePage}
                nextPageText="next"
                prevPageText="prev"
            />
        </React.Fragment>
    )
}

export default CustomPagination;

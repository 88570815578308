import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import {Container,Col,Row }from 'react-bootstrap'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'


const mapStateToProps = (state) => {
    return {
        rtlMode: state
    };


}
const mapDispatchToProps = dispatch => ({

})




const FooterStyle = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {

    })

    return (
        <React.Fragment>
            <footer className="iq-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* <ul className="list-inline mb-0">
                                <li className="list-inline-item"><Link to="/user-privacy-setting">Privacy Policy</Link></li>
                                <li className="list-inline-item"><Link to="/user-account-setting">Terms of Use</Link></li>
                            </ul> */}
                        </div>
                        <div className="col-lg-6 text-right">
                            <p><small>Copyright © {new Date().getFullYear()}. All rights reserved.</small></p>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle)
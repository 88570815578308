import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from '../../../components/Card';
import AddModal from './add-subcategory';
import { apiCall } from "../../../utils/apiWrapper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SubCategoryList = ({ }) => {
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [addModalShow, setAddModalShow] = useState(false);
    const [editData, setEditData] = useState('');
    const [form, setForm] = useState({});

    const getSubCategory = () => {
        apiCall('subcategory', 'get')
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.data);
                    setSubCategoryList(res.data.data);
                }
            })
    };

    const deleteSubCategory = (data) => {
        apiCall(`subcategory/${data?._id}`, 'put', { isActive: false })
            .then((res) => {
                if (res.status === 200) {
                    getSubCategory();
                }
            });
    };

    const onClickEdit = (data) => {
        setAddModalShow(true);
        setEditData(data);
    };

    useEffect(() => {
        getSubCategory();
    }, []);

    useEffect(() => {
        if (addModalShow) {
            return;
        }
        setEditData('');
    }, [addModalShow]);

    useEffect(() => {
        if (editData) {
            setForm({ SubcategoryName: editData?.SubcategoryName, subcategoryDescription: editData?.subcategoryDescription });
        }
    }, [editData]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Header.Title>
                                    <h4 className="card-title">Sub Category Lists</h4>
                                </Card.Header.Title>
                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <span onClick={() => setAddModalShow(true)} className="btn btn-primary">Add Sub Category</span>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {(SubCategoryList.length > 0) ? (
                                    <div className="table-view">
                                        <table className="data-tables table movie_table " style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "10%" }}>No</th>
                                                    <th style={{ width: "20%" }}>Name</th>
                                                    <th style={{ width: "50%" }}>Description</th>
                                                    <th style={{ width: "50%" }}>Category</th>
                                                    <th style={{ width: "20%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SubCategoryList?.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>1</td>
                                                        <td>{data?.SubcategoryName}</td>
                                                        <td>
                                                            <p className="mb-0">
                                                                {data?.subcategoryDescription}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">
                                                                {data?.categories?.categoryName}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="flex align-items-center list-user-action">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                                    <Link onClick={() => onClickEdit(data)} to="#" className="iq-bg-success"><i className="ri-pencil-line"></i></Link>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                    <Link onClick={() => deleteSubCategory(data)} to="#" className="iq-bg-primary"><i className="ri-delete-bin-line"></i></Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div>
                                        {["", "", "", "", "", "", "", "", ""].map(() => <Skeleton baseColor="#181142" highlightColor="#241B57" className="mt-4" height={50} />)}
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <AddModal form={form} setForm={setForm} setAddModalShow={setAddModalShow} addModalShow={addModalShow} getCategory={getSubCategory} editData={editData} />
            </Container>

        </>
    )
}
export default SubCategoryList;
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";
import Select, { StylesConfig } from 'react-select';

const getTheme = (theme) => ({
    ...theme,
    borderRadius: "6px",
    colors: {
        ...theme.colors,
        text: 'orangered',
        primary25: '#241B57',
        primary: '#181142',
        primary75: 'green',
        primary50: '#241B57',
        danger: 'white',
        dangerLight: 'red',
        neutral0: '#181142',
        neutral5: 'hsl(0, 0%, 95%)',
        neutral10: '#241B57',
        neutral20: '#181142',
        neutral30: 'hsl(0, 0%, 70%)',
        neutral40: '#181142',
        neutral50: 'white',
        neutral60: 'white',
        neutral70: 'hsl(0, 0%, 30%)',
        neutral80: 'white',
        neutral90: 'hsl(0, 0%, 10%)',
    },
});

const colourStyles = {
    control: (styles) => ({ ...styles, cursor: 'pointer' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? '#241B57' : '#181142',
            border: isSelected ? '8px solid #181142' : '0px',
            color: isDisabled ? '#fffffff' : '##01122c',
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
};

const AddSubCategory = ({ form, setForm, setAddModalShow, addModalShow, getCategory, editData }) => {
    let navigate = useNavigate();
    const [error, setError] = useState({});
    const [createLoading, setCreateLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    const onAddSubCategory = () => {
        setCreateLoading(true);
        if (!form?.SubcategoryName) {
            setError({
                SubcategoryName: 'please enter sub category name'
            });
            setCreateLoading(false);
            return;
        }
        let newCategory = {
            SubcategoryName : form?.SubcategoryName,
            categories : form.category._id,
            subcategoryDescription: form?.subcategoryDescription

        }
        apiCall('subcategory', 'post', newCategory)
            .then((res) => {
                if (res.status === 200) {
                    getCategory();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };

    const onEditSubCategory = () => {
        setCreateLoading(true);
        if (!form?.SubcategoryName) {
            setError({
                SubcategoryName: 'please enter sub category name'
            });
            setCreateLoading(false);
            return;
        }
        apiCall(`category/${editData?._id}`, 'put', form)
            .then((res) => {
                if (res.status === 200) {
                    getCategory();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };
    useEffect(() => {
      apiCall("category", "get").then((res) => {
        if (res.status === 200) {
          setCategoryList(res.data.data);
        }
      });
    }, []);
    useEffect(() => {
        if (form.SubcategoryName) {
            setError({
                SubcategoryName: ''
            })
        }
    }, [form]);

    return (
      <>
        <Container fluid>
          <Modal
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
            show={addModalShow}
          >
            <Row>
              <Col sm="12">
                <Card>
                  <Card.Header.Title className="row justify-content-center mt-4">
                    <h4 className="card-title">
                      {editData ? "Edit" : "Add"} Sub Category
                    </h4>
                  </Card.Header.Title>
                  <Card.Body>
                    <Row>
                      <Col lg="12">
                        <Form>
                          <Form.Group className="col-md-12 mb-3">
                            <Select
                              isMulti={false}
                              theme={getTheme}
                              styles={colourStyles}
                              onChange={(value) =>
                                setForm({ ...form, category: value })
                              }
                              placeholder="Choose Category"
                              options={categoryList}
                              getOptionValue={(option) => `${option["_id"]}`}
                              getOptionLabel={(option) =>
                                `${option["categoryName"]} `
                              }
                              value={form?.category}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  SubcategoryName: e.target.value,
                                })
                              }
                              isInvalid={error?.["SubcategoryName"]}
                              value={
                                form?.SubcategoryName
                                  ? form?.SubcategoryName
                                  : ""
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {error?.["SubcategoryName"]}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              as="textarea"
                              id="text"
                              name="text"
                              rows="5"
                              placeholder="Description"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  subcategoryDescription: e.target.value,
                                })
                              }
                              value={
                                form?.subcategoryDescription
                                  ? form?.subcategoryDescription
                                  : ""
                              }
                            />
                          </Form.Group>
                          <Form.Group className="form-group mt-4 row justify-content-center">
                            <Button
                              className="mx-2"
                              type="button"
                              onClick={() => {
                                editData
                                  ? onEditSubCategory()
                                  : onAddSubCategory();
                              }}
                              variant=" btn-primary"
                            >
                              {editData ? "Update" : "Submit"}{" "}
                              {createLoading && (
                                <Spinner size="sm" animation="border" />
                              )}
                            </Button>
                            <Button
                              className="mx-2"
                              type="reset"
                              variant=" btn-danger"
                              onClick={() => setAddModalShow(false)}
                            >
                              Cancel
                            </Button>
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal>
        </Container>
      </>
    );
}
export default AddSubCategory;
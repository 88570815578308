const localEnv = {
    baseUrl: "https://audioapi.ottsplatform.com/api/"
    //baseUrl: "http://3.111.30.253:3000/api/"
    //baseUrl: "http://localhost:3001/api/"
}

const stagingEnv = {
    baseUrl: "https://api.sahkaritavani.in/api/"
}

const prodEnv = {
    baseUrl: "https://api.sahkaritavani.in/api/"
}

module.exports = ((process.env.NODE_ENV === "production") ? prodEnv : ((process.env.NODE_ENV === "staging") ? stagingEnv : localEnv));

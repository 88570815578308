import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const AddShow = ({ setAddModalShow, addModalShow, getStreamChannelList, editData }) => {
   let navigate = useNavigate();
   const [streamForm, setStreamForm] = useState({});
   const [imageState, setImageState] = useState('');
   const [imageInput, setImageInput] = useState('');
   const [createLoading, setCreateLoading] = useState(false);

   const handleImageChange = async (event) => {
      console.log(event.target.files[0]);

      if (event.target.files[0]) {
         setImageInput(event.target.files[0]);
         var file = event.target.files[0];
         var reader = new FileReader();
         var url = reader.readAsDataURL(file);
         reader.onprogress = (e) => {
            console.log('in progress');
         };

         reader.onloadend = (e) => {
            let curentFile = e.target.result;

            // The blob gives us a URL to the video file:
            //let url = window.URL.createObjectURL(curentFile);

            //setImageUrl(url);
            setImageState(file);
         };
      }
      //handleUpdatePhoto(event, event.target.files[0]);
   };

   const onAddStream = (streamData) => {
      apiCall('stream/create', 'post', streamData)
         .then((res) => {
            setCreateLoading(false);
            if (res.status === 200) {
               getStreamChannelList();
               setAddModalShow(false);
            }
         })
   };

   useEffect(() => {
      console.log(editData, '---------++++++++++++>>>>>>>>>>');
      setStreamForm({ ...editData });
   }, [editData]);

   const onUpdateStream = (streamId, streamData) => {
      apiCall(`stream/${streamId}`, 'put', streamData)
         .then((res) => {
            setCreateLoading(false);
            if (res.status === 200) {

            }
         })
   };

   const uploadImage = () => {
      setCreateLoading(true);
      const formDataImage = new FormData();
      formDataImage.append('thumbnail', imageState);
      apiCall('video/uploadThumbnail', 'post', formDataImage, 'multipart/form-data')
         .then((res) => {
            if (res.status === 200) {
               console.log(res?.data?.link, '-------------image');
               onAddStream({
                  title: streamForm?.title,
                  description: streamForm?.description,
                  url: res?.data?.link,
                  thumbnail: res?.data?.link,
                  adminId: localStorage.getItem('userId'),
               });
            }
            else {
               setCreateLoading(false);
            }
         })
   };

   const uploadImageAsync = async () => {
      if (!imageState) {
         return false;
      }
      setCreateLoading(true);
      const formDataImage = new FormData();
      formDataImage.append('thumbnail', imageState);
      return apiCall('video/uploadThumbnail', 'post', formDataImage, 'multipart/form-data')
         .then((res) => {
            if (res.status === 200) {
               console.log(res?.data?.link, '-------------image');
               return res?.data?.link;
            }
            else {
               return false;
            }
         })
   };

   const submitStreamData = async () => {
      let imageUploaded = await uploadImageAsync();
      console.log(imageUploaded, '------------<<>>>>>.');

      if (editData) {
         onUpdateStream(editData?._id, imageUploaded ? {
            title: streamForm?.title,
            description: streamForm?.description,
            thumbnail: imageUploaded,
         } : {
            title: streamForm?.title,
            description: streamForm?.description
         })
      } else {
         onAddStream({
            title: streamForm?.title,
            description: streamForm?.description,
            url: imageUploaded,
            thumbnail: imageUploaded,
            adminId: localStorage.getItem('userId'),
         });
      }

   }

   useEffect(() => {
      if (addModalShow) {
         return;
      }
      setImageInput('');
      setImageState('');
      setStreamForm('');
   }, [addModalShow]);

   return (
      <>
         <Container fluid>
            <Modal style={{ paddingTop: '100px', paddingBottom: '100px' }} show={addModalShow}>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header.Title className="row justify-content-center mt-4">
                           <h4 className="card-title">Add Stream</h4>
                        </Card.Header.Title>
                        <Card.Body>
                           <Form>
                              <Row>
                                 <Col md="6" className="form-group mb-1">
                                    <Form.Control value={streamForm?.title} onChange={(e) => setStreamForm({ ...streamForm, title: e.target.value })} type="text" placeholder="Title" />
                                 </Col>
                                 <Col md="6" className="form_gallery form-group mb-1">
                                    <label id="gallery2" htmlFor="form_gallery-upload">Upload Image</label>
                                    <input
                                       onChange={handleImageChange}
                                       data-name="#gallery2"
                                       id="form_gallery-upload"
                                       name="gallery"
                                       className="form_gallery-upload"
                                       type="file"
                                       accept=".png, .jpg, .jpeg"
                                       value={imageInput?.imageInput}
                                    />
                                 </Col>
                                 <Form.Group className="col-12 mt-0">
                                    <Form.Control value={streamForm?.description} onChange={(e) => setStreamForm({ ...streamForm, description: e.target.value })} as="textarea" id="text1" name="text" rows="5" placeholder="Description"></Form.Control>
                                 </Form.Group>
                              </Row>
                              <Row>
                                 <Form.Group className="col-12 mt-4 row justify-content-center">
                                    <Button className="mx-2" type="button" onClick={async () => await submitStreamData()} variant="primary">
                                       {editData ? 'Update ' : 'Create '} Stream {' '}
                                       {createLoading && <Spinner size="sm" animation="border" />}
                                    </Button>{' '}
                                    <Button className="mx-2" type="reset" onClick={() => setAddModalShow(false)} variant="danger">Cancel</Button>
                                 </Form.Group>
                              </Row>
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Modal>
         </Container>
      </>
   )
}
export default AddShow;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const RecordingPlayerModal = ({ setAddModalShow, addModalShow, videoUrl }) => {
    const [uploadedVideoDetail, setUploadedVideoDetail] = useState({});
    const [currentPlayer, setCurrentPlayer] = useState('');
    const [currentPlayerId, setCurrentPlayerId] = useState(`video-${Date.now()}`);

    const { registerIVSTech, videojs } = window;

    const intializePlayer = (url) => {
        console.log(`${currentPlayerId}`);
        // let player = videojs(`${currentPlayerId}`);
        // if (player?.src()) {
        //     player.dispose();
        // }

        // Register Amazon IVS as playback technology for Video.js
        registerIVSTech(videojs);

        let localPlayer = videojs(`${currentPlayerId}`, {
            controls: true,
            autoplay: true,
            preload: 'auto',
            techOrder: ["AmazonIVS"],
        });
        let newUrl = videoUrl;
        localPlayer.src(newUrl);
        setCurrentPlayer(localPlayer);
    };

    useEffect(() => {
        intializePlayer();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCurrentPlayerId(`video-${Date.now()}`);
    }, [videoUrl]);

    useEffect((currentPlayer) => () => {
        currentPlayer?.dispose?.();
    }, []);

    return (
        <>
            <Container>
                <Modal size='lg' style={{ paddingTop: '100px', paddingBottom: '100px' }} show={addModalShow}>
                    <div id="instructions video" className="video-area m-4 p-4">
                        <video
                            id={`${currentPlayerId}`}
                            className="video-js vjs-default-skin vjs-fluid"
                            controls
                        >
                        </video>
                        <div className="text-center">
                            <Button className="mt-4 mx-2 px-4" type="reset" onClick={() => setAddModalShow(false)} variant="primary">
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            </Container>
        </>
    )
}
export default RecordingPlayerModal;

import React, { useEffect, useState } from 'react';
// import React,{useEffect,useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
// import { Link} from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap';
import Scrollbar from 'smooth-scrollbar';

// import {connect} from "react-redux";
// import {getDarkMode} from '../../../../store/mode'

//img
import logo from '../../../../../src/assets/images/logo.png'


// function mapStateToProps(state) {
//     return {
//         darkMode: getDarkMode(state)
//     };
// }


const SidebarStyle = (props) => {

    //Collapse state
    const [sideNavExpanded, setSideNavExpanded] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    const [activesubMenu1, setSubmenu1] = useState(false)
    // const[activesubMenu2,setSubmenu2] = useState(false)


    //location
    let location = useLocation();

    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#sidebar-scrollbar'))
        }
    )
    return (
        <React.Fragment>
            <div className="iq-sidebar" >
                <div className="iq-sidebar-logo d-flex justify-content-between">
                    <Link to="/" className="header-logo">
                        {/* <img src={logo} className="img-fluid rounded-normal" alt="" /> */}
                        <div className="logo-title">
                            <span className="text-primary text-uppercase">Audio Demo App</span>
                        </div>
                    </Link>
                    {/* <div className="iq-menu-bt-sidebar">
                        <div className="iq-menu-bt align-self-center">
                            <div className="wrapper-menu" onClick={minisidbar}>
                                <div className="main-circle"><i className="las la-bars"></i></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="iq-menu" onSelect={(e) => setActiveMenu(e)}>
                            {/* <li className={''}>
                                <a target="_blank" rel="noopener noreferrer" href="https://ottsplatform.com/" className="">
                                    <i className="ri-arrow-right-line"></i>
                                    <span>Visit site</span>
                                </a>
                            </li> */}
                            <li className={`${location?.pathname === '/' ? 'active' : ''} `}>
                                <Link to="/" className="iq-waves-effect">
                                    <i className="las la-home iq-arrow-left"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            {/* <li className={`${location?.pathname === "/add-category" ? 'active' : ''} `}>
                                <Link to="/add-category" className="iq-waves-effect">
                                    <i className="las la-user-plus"></i>
                                    <span>Add Category</span>
                                </Link>
                            </li> */}
                            <li className={`${location?.pathname === "/category" ? 'active' : ''} `}>
                                <Link to="/category" className="iq-waves-effect">
                                    <i className="las la-stream"></i>
                                    <span>Category</span>
                                </Link>
                            </li>
                            <li className={`${location?.pathname === "/subcategory" ? 'active' : ''} `}>
                                <Link to="/subcategory" className="iq-waves-effect">
                                    <i className="las la-stream"></i>
                                    <span>Sub Category</span>
                                </Link>
                            </li>
                            {/* <li className={`${location?.pathname === "/add-tag" ? 'active' : ''} `}>
                                <Link to="/add-tag" className="iq-waves-effect">
                                    <i className="las la-user-plus"></i>
                                    <span>Add Tag</span>
                                </Link>
                            </li> */}
                            <li className={`${location?.pathname === "/tag" ? 'active' : ''} `}>
                                <Link to="/tag" className="iq-waves-effect">
                                    <i className="las la-tags"></i>
                                    <span>Tag List</span>
                                </Link>
                            </li>
                            {/* <li className={`${location?.pathname === "/add-video" ? 'active' : ''} `}>
                                <Link to="/add-video" className="iq-waves-effect">
                                    <i className="las la-user-plus"></i>
                                    <span>Add Video</span>
                                </Link>
                            </li> */}
                            <li className={`${location?.pathname === "/video" ? 'active' : ''} `}>
                                <Link to="/video" className="iq-waves-effect">
                                    <i className="lab la-youtube"></i>
                                    <span>Audio List</span>
                                </Link>
                            </li>
                        {/* 
                            <li className={`${location?.pathname === '/stream' ? 'active' : ''} `}>
                                <Link to="/stream" className="iq-waves-effect">
                                    <i className="las la-video"></i>
                                    <span>Stream</span>
                                </Link>
                            </li> 
                            <li className={`${location?.pathname === '/recording-list' ? 'active' : ''} `}>
                                <Link to="/recording-list" className="iq-waves-effect">
                                    <i className="las la-save"></i>
                                    <span>Recordings</span>
                                </Link>
                            </li>
                            <li className={`${location?.pathname === '/trimmed-video' ? 'active' : ''} `}>
                                <Link to="/trimmed-video" className="iq-waves-effect">
                                    <i className="las la-cut"></i>
                                    <span>Trimmed Video</span>
                                </Link>
                            </li>
                            <li className={`${location?.pathname === '/banner-text' ? 'active' : ''} `}>
                                <Link to="/banner-text" className="iq-waves-effect">
                                    <i className="las la-sms"></i>
                                    <span>Banner Text</span>
                                </Link>
                            </li>*/}
                        </Accordion>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SidebarStyle;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Spinner, ProgressBar } from 'react-bootstrap';
import Card from '../../../components/Card';
import Select, { StylesConfig } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const AddMovie = ({ setAddModalShow, addModalShow, getVideoList, editData, form, setForm }) => {
    let navigate = useNavigate();
    const [imageState, setImageState] = useState('');
    const [videoState, setVideoState] = useState('');
    const [videoUrl, setVideoUrl] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [videoUploading, setVideoUploading] = useState(false);
    const [showOnHome, setShowOnHome] = useState(false);
    const [showOnCategory, setShowOnCategory] = useState(false);
    const [progressBar, setProgressBar] = useState(0);
    const [videoProperty, setVideoProperty] = useState({});
    const [autoGenerateThumbnail, setAutoGenerateThumbnail] = useState(false);

    const getTheme = (theme) => ({
        ...theme,
        borderRadius: "6px",
        colors: {
            ...theme.colors,
            text: 'orangered',
            primary25: '#241B57',
            primary: '#181142',
            primary75: 'green',
            primary50: '#241B57',
            danger: 'white',
            dangerLight: 'red',
            neutral0: '#181142',
            neutral5: 'hsl(0, 0%, 95%)',
            neutral10: '#241B57',
            neutral20: '#181142',
            neutral30: 'hsl(0, 0%, 70%)',
            neutral40: '#181142',
            neutral50: 'white',
            neutral60: 'white',
            neutral70: 'hsl(0, 0%, 30%)',
            neutral80: 'white',
            neutral90: 'hsl(0, 0%, 10%)',
        },
    });

    const colourStyles = {
        control: (styles) => ({ ...styles, cursor: 'pointer' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#241B57' : '#181142',
                border: isSelected ? '8px solid #181142' : '0px',
                color: isDisabled ? '#fffffff' : '#ffffff',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    };

    const handleImageChange = async (event) => {
        console.log(event.target.files[0]);

        if (event.target.files[0]) {
            var file = event.target.files[0];
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onprogress = (e) => {
                console.log('in progress');
            };

            reader.onloadend = (e) => {
                let curentFile = e.target.result;

                // The blob gives us a URL to the video file:
                //let url = window.URL.createObjectURL(curentFile);

                //setImageUrl(url);
                setImageState(file);
            };
        }
        //handleUpdatePhoto(event, event.target.files[0]);
    };
   
    const handleVideoChange = async (event) => {
        console.log(event.target.files[0]);

        if (event.target.files[0]) {
            var file = event.target.files[0];
            var reader = new FileReader();
            var url = reader.readAsArrayBuffer(file);
            reader.onprogress = (e) => {
                console.log('in progress');
            };

            reader.onloadend = (e) => {
                let buffer = e.target.result;

                // We have to convert the buffer to a blob:
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });

                // The blob gives us a URL to the video file:
                let url = window.URL.createObjectURL(videoBlob);

                setVideoUrl(url);
                setVideoState(file);
            };
        }
        //handleUpdatePhoto(event, event.target.files[0]);
    };

    const uploadImage = (videoUrl) => {
        if (imageState) {
            const formDataImage = new FormData();
            formDataImage.append('thumbnail', imageState);
            apiCall('video/uploadThumbnail', 'post', formDataImage, 'multipart/form-data')
                .then((res) => {
                    if (res.status === 200) {
                        let tags = [];
                        console.log(">>",  form?.category?._id);
                        console.log(">>",  form?.subcategory?._id);

                        form?.tags.map(data => tags.push(data?._id));
                        addVideo({
                            title: form?.title,
                            description: form?.description,
                            url: videoUrl,
                            thumbnail: res?.data?.link,
                            categories: form?.category?._id,
                            subcategories : form?.subcategory?._id,
                            tags: tags,
                            duration: document.getElementById('video')?.duration,
                        });
                    }
                    else {
                        setVideoUploading(false);
                    }
                });
        } else {
            const formDataImage = new FormData();
            var canvas = document.getElementById('canvas');
            canvas.toBlob((blob) => {
                formDataImage.append('thumbnail', blob);
                apiCall('video/uploadThumbnail', 'post', formDataImage, 'multipart/form-data')
                    .then((res) => {
                        if (res.status === 200) {
                            let tags = [];
                            form?.tags.map(data => tags.push(data?._id));
                       
                            addVideo({
                                title: form?.title,
                                description: form?.description,
                                url: videoUrl,
                                thumbnail: res?.data?.link,
                                categories: form?.category?._id,
                                subcategories: form?.subcategory?._id,
                                tags: tags,
                                duration: document.getElementById('video')?.duration
                            });
                        }
                        else {
                            setVideoUploading(false);
                        }
                    })
            });
        }
    };

    const uploadVideo = () => {
        setVideoUploading(true);
        const onUploadProgress = (progressEvent) => {
            const percentCompleted = (progressEvent.loaded / progressEvent.total);
            setProgressBar(percentCompleted * 100);
        }

        const formDataVideo = new FormData();
       
        formDataVideo.append('video', videoState)
        apiCall('video/uploadVideo', 'post', formDataVideo, 'multipart/form-data', onUploadProgress)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res?.data?.fileName, '-------------video');
                    uploadImage(res?.data?.fileName);
                } else {
                    setVideoUploading(false);
                }
            })
    };

    const addVideo = (data) => {
      console.log("addVideo data >>", data);
        apiCall('video', 'post', data)
            .then((res) => {
                setVideoUploading(false);
                if (res.status === 200) {
                    getVideoList();
                    setAddModalShow(false);
                }
            })
    };

    const updateVideo = (id, updateData) => {
      console.log("updateVideo updateData >>", updateData);
        apiCall(`video/${id}`, 'put', updateData)
            .then((res) => {
                if (res.status === 200) {
                    getVideoList();
                    setAddModalShow(false);
                    setVideoUploading(false);
                }
            })
    };

    const onUpdateVideo = () => {
        setVideoUploading(true);

        if (imageState) {
            const formDataImage = new FormData();
            formDataImage.append('thumbnail', imageState)
            apiCall('video/uploadThumbnail', 'post', formDataImage, 'multipart/form-data')
                .then((res) => {
                    if (res.status === 200) {
                        let tags = [];
                        form?.tags.map(data => tags.push(data?._id));
                        updateVideo(editData?._id, {
                            title: form?.title,
                            description: form?.description,
                            thumbnail: res?.data?.link,
                            categories: form?.category?._id,
                            subcategories: form?.subcategory?._id,
                            tags: tags,
                        });
                    }
                    else {
                        setVideoUploading(false);
                    }
                })
        }
        else {
            let tags = [];
            form?.tags.map(data => tags.push(data?._id));
            updateVideo(editData?._id, {
                title: form?.title,
                description: form?.description,
                categories: form?.category?._id,
                subcategories: form?.subcategory?._id,
                subcategories: form?.subcategory?._id,
                tags: tags,
                showOnHomeScreen: showOnHome,
                showOnCategoryScreen: showOnCategory,
            });
        }
    };

    useEffect(() => {
        let video = document.getElementById('video');
        if (!video) {
            return;
        }
        video.src = videoUrl;
    }, [videoState]);

    useEffect(() => {
        apiCall('category', 'get')
            .then((res) => {
                if (res.status === 200) {
                    setCategoryList(res.data.data);
                }
            })
        apiCall('tag', 'get')
            .then((res) => {
                if (res.status === 200) {
                    setTagList(res.data.data);
                }
            })
        apiCall("subcategory", "get").then((res) => {
          if (res.status === 200) {
            setSubCategoryList(res.data.data);
          }
        });
    }, []);

    useEffect(() => {
        if (addModalShow) {
            return;
        }
        setForm('');
        setVideoUploading(false);
        setImageState('');
        setVideoState('');
        setProgressBar(0);
        setVideoProperty({});
        setAutoGenerateThumbnail(false);
    }, [addModalShow]);

    const capture = (e) => {
        e.preventDefault();
        var canvas = document.getElementById('canvas');
        var video = document.getElementById('video');
        let screenshotImg = document.getElementById('screenshotImg');
        setVideoProperty({ width: video.videoWidth, height: video.videoHeight })
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        screenshotImg.src = canvas.toDataURL();
    };
     const changeSelectOptionHandler = (event) => {
      console.log("------", event);
      setForm({ ...form, category: event._id });
     
      apiCall(`subcategory/getbycategoryId/${event._id}`, 'get')
      .then((res) => {
          if (res.status === 200) {
              console.log(res.data.data);
              setSubCategoryList(res.data.data);
          }
      })
    };

    return (
      <>
        <Container fluid>
          <Modal
            size={editData ? "md" : "xl"}
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
            show={addModalShow}
          >
            <Row>
              <Col sm="12">
                <Card>
                  <Card.Header.Title className="row justify-content-center mt-4">
                    <h4 className="card-title">
                      {editData ? "Update" : "Add"} Music
                    </h4>
                  </Card.Header.Title>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col>
                          <Row>
                            <Form.Group className="col-12 mb-3">
                              <Form.Control
                                onChange={(e) =>
                                  setForm({ ...form, title: e.target.value })
                                }
                                type="text"
                                placeholder="Title"
                                value={form?.title}
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Form.Group className="col-md-12 mb-3">
                              <Select
                                isMulti={false}
                                theme={getTheme}
                                onChange={(value) =>{
                                  setForm({ ...form, category: value })
                                }}
                                //onChange={ changeSelectOptionHandler}
                                placeholder="Choose Category"
                                options={categoryList}
                                styles={colourStyles}
                                getOptionValue={(option) => `${option["_id"]}`}
                                getOptionLabel={(option) =>
                                  `${option["categoryName"]}`
                                }
                                value={form?.category}
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Form.Group className="col-md-12 mb-3">
                              <Select
                                isMulti={false}
                                theme={getTheme}
                                onChange={(value) =>
                                  setForm({ ...form, subcategory: value })
                                }
                                placeholder="Choose sub Category"
                                options={SubCategoryList}
                                styles={colourStyles}
                                getOptionValue={(option) => `${option["_id"]}`}
                                getOptionLabel={(option) =>
                                  `${option["SubcategoryName"]}`
                                }
                                value={form?.subcategory}
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Group className="col-md-12 mb-3">
                          <Select
                            isMulti
                            theme={getTheme}
                            onChange={(value) =>
                              setForm({ ...form, tags: value })
                            }
                            placeholder="Choose Tag"
                            options={tagList}
                            styles={colourStyles}
                            getOptionValue={(option) => `${option["_id"]} `}
                            getOptionLabel={(option) => `${option["tagName"]} `}
                            value={form?.tags}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="col-12 mb-3">
                          <Form.Control
                            as="textarea"
                            id="description"
                            name="description"
                            rows="5"
                            onChange={(e) =>
                              setForm({ ...form, description: e.target.value })
                            }
                            placeholder="Description"
                            value={form?.description}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        {!editData ? (
                          <>
                            <Col lg="6">
                              <div className="d-block position-relative">
                                {videoState ? (
                                  <div
                                    className="form_video-upload"
                                    style={{ height: "384px" }}
                                  >
                                    <input
                                      style={{ cursor: "pointer" }}
                                      onChange={handleVideoChange}
                                      type="file"
                                      accept="audio/*.mp3"
                                      multiple
                                    />
                                    <div>
                                      <div>
                                        <video
                                          id="video"
                                          width="350"
                                          height="150"
                                          controls
                                        >
                                          <source />
                                        </video>
                                      </div>
                                      <Button className="mr-2">
                                        Upload Another
                                      </Button>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="form_video-upload"
                                    style={{ height: "184px" }}
                                  >
                                    <input
                                      style={{ cursor: "pointer" }}
                                      onChange={handleVideoChange}
                                      type="file"
                                      accept="audio/*.mp3"
                                      multiple
                                    />
                                    <div>
                                      Upload Music{" "}
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col lg="6">
                              <Col className="mt-2">
                                {/* <div className="checkbox d-inline-block mr-4">
                                                                <input onClick={() => setAutoGenerateThumbnail(true)} type="checkbox" className="checkbox-input" id="checkbox1" checked={autoGenerateThumbnail} />
                                                                <label htmlFor="checkbox1" className="text-white ml-2">Auto Generate Thumbnail</label>
                                                            </div> */}
                                <div className="checkbox d-inline-block mr-2">
                                  {/* <input onClick={() => setAutoGenerateThumbnail(false)} type="checkbox" className="checkbox-input" id="checkbox2" checked={!autoGenerateThumbnail} /> */}
                                  <label
                                    htmlFor="checkbox2"
                                    className="text-white ml-2"
                                  >
                                    Upload Custom Thumbnail
                                  </label>
                                </div>
                              </Col>
                              <div className="d-block position-relative">
                                {autoGenerateThumbnail ? (
                                  <div
                                    className="form_video-upload"
                                    style={{ height: "338px" }}
                                  >
                                    {videoState ? (
                                      <div>
                                        <div>
                                          <img
                                            style={{
                                              width: "390px",
                                              height: "220px",
                                            }}
                                            id="screenshotImg"
                                            alt="capture_image"
                                          ></img>
                                        </div>
                                        <br />
                                        {/* <Button onClick={capture} className="">Capture Thumbnail</Button> */}
                                      </div>
                                    ) : (
                                      <div>
                                        <input
                                          style={{ cursor: "pointer" }}
                                          onChange={handleVideoChange}
                                          type="file"
                                          accept="video/mp4,video/x-m4v,video/*"
                                          multiple
                                        />
                                        <button
                                          onClick={capture}
                                          className="btn btn-primary"
                                        >
                                          Capture Thumbnail
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="col-12 form_gallery form-group">
                                    <label
                                      id="gallery2"
                                      htmlFor="form_gallery-upload"
                                    >
                                      {imageState
                                        ? imageState?.name
                                        : "Upload Image"}
                                    </label>
                                    <input
                                      onChange={handleImageChange}
                                      data-name="#gallery2"
                                      id="form_gallery-upload"
                                      className="form_gallery-upload"
                                      type="file"
                                      accept=".png, .jpg, .jpeg"
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </>
                        ) : (
                          <div className="col-12 form_gallery form-group">
                            <label id="gallery2" htmlFor="form_gallery-upload">
                              {imageState ? imageState?.name : "Upload Image"}
                            </label>
                            <input
                              onChange={handleImageChange}
                              data-name="#gallery2"
                              id="form_gallery-upload"
                              className="form_gallery-upload"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>
                        )}
                      </Row>
                      {/* <Row>
                                            <Col className="mt-2">
                                                <div className="checkbox d-inline-block mr-4">
                                                    <input onClick={() => setShowOnHome(!showOnHome)} type="checkbox" className="checkbox-input" id="checkbox1" checked={showOnHome || editData?.showOnHomeScreen} />
                                                    <label htmlFor="checkbox1" className="text-white ml-2">Show on home carousel</label>
                                                </div>
                                                <div className="checkbox d-inline-block mr-2">
                                                    <input onClick={() => setShowOnCategory(!showOnCategory)} type="checkbox" className="checkbox-input" id="checkbox2" checked={showOnCategory || editData?.showOnCategoryScreen} />
                                                    <label htmlFor="checkbox2" className="text-white ml-2">Show on category carousel</label>
                                                </div>
                                            </Col>
                                        </Row> */}
                      {videoUploading && (
                        <ProgressBar className="mb-3" now={progressBar} />
                      )}
                      <Row>
                        <Form.Group className="col-12 mt-3 row justify-content-center">
                          <Button
                            className="mx-2"
                            type="button"
                            onClick={() => {
                              editData ? onUpdateVideo() : uploadVideo();
                            }}
                            variant="primary"
                          >
                            {editData ? "Update" : "Upload"}
                            {videoUploading && (
                              <Spinner size="sm" animation="border" />
                            )}
                          </Button>{" "}
                          <Button
                            className="mx-2"
                            type="reset"
                            onClick={() => setAddModalShow(false)}
                            variant="danger"
                          >
                            Cancel
                          </Button>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
                <canvas
                  style={{ display: "none" }}
                  width={videoProperty?.width}
                  height={videoProperty?.height}
                  id="canvas"
                ></canvas>
              </Col>
            </Row>
          </Modal>
        </Container>
      </>
    );
}
export default AddMovie;
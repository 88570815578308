import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const AddCategory = ({ form, setForm, setAddModalShow, addModalShow, getCategory, editData }) => {
    let navigate = useNavigate();
    const [error, setError] = useState({});
    const [createLoading, setCreateLoading] = useState(false);

    const onAddCategory = () => {
        setCreateLoading(true);
        if (!form?.categoryName) {
            setError({
                categoryName: 'please enter category name'
            });
            setCreateLoading(false);
            return;
        }
        apiCall('category', 'post', form)
            .then((res) => {
                if (res.status === 200) {
                    getCategory();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };

    const onEditCategory = () => {
        setCreateLoading(true);
        if (!form?.categoryName) {
            setError({
                categoryName: 'please enter category name'
            });
            setCreateLoading(false);
            return;
        }
        apiCall(`category/${editData?._id}`, 'put', form)
            .then((res) => {
                if (res.status === 200) {
                    getCategory();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };

    useEffect(() => {
        if (form.categoryName) {
            setError({
                categoryName: ''
            })
        }
    }, [form]);

    return (
        <>
            <Container fluid>
                <Modal style={{ paddingTop: '100px', paddingBottom: '100px' }} show={addModalShow}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header.Title className="row justify-content-center mt-4">
                                    <h4 className="card-title">{editData ? 'Edit' : 'Add'} Category</h4>
                                </Card.Header.Title>
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <Form>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Name"
                                                        onChange={(e) => setForm({ ...form, categoryName: e.target.value })}
                                                        isInvalid={error?.['categoryName']}
                                                        value={form?.categoryName ? form?.categoryName : ''}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{error?.['categoryName']}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control
                                                        as="textarea"
                                                        id="text"
                                                        name="text"
                                                        rows="5"
                                                        placeholder="Description"
                                                        onChange={(e) => setForm({ ...form, categoryDescription: e.target.value })}
                                                        value={form?.categoryDescription ? form?.categoryDescription : ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group mt-4 row justify-content-center">
                                                    <Button className="mx-2" type="button" onClick={() => { editData ? onEditCategory() : onAddCategory() }} variant=" btn-primary">
                                                        {editData ? 'Update' : 'Submit'}{' '} {createLoading && <Spinner size="sm" animation="border" />}
                                                    </Button>
                                                    <Button className="mx-2" type="reset" variant=" btn-danger" onClick={() => setAddModalShow(false)}>Cancel</Button>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal>
            </Container>

        </>
    )
}
export default AddCategory;
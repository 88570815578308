const initstate = {
    rtlMode: 'ltr'
}

// Action/Dispatch
export const rtlModeAction = (value) => {
    return { type: 'RTLMODE', value }
}

// Mutations/Reducer
const Mode = (state = initstate, action) => {
    switch (action.type) {
        case 'RTLMODE':
            return Object.assign({}, state, {
                rtlMode: action.value
            })
        default:
            return state
    }
}

// Selectores
export const getRtlMode = (state) => state.mode.rtlMode;

export default Mode
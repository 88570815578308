import React from 'react';
import { Outlet } from 'react-router-dom';


// Partials
import HeaderStyle1 from '../components/partials/backend/headerstyle/headerstyle1';
import SidebarStyle from '../components/partials/backend/sidebarstyle/sidebarstyle'
import FooterStyle from '../components/partials/backend/footerstyle/footerstyle'


const Layout1 = ({ children }) => {


    return (
        <>
            <div className="wrapper">
                <SidebarStyle />
                <HeaderStyle1 />
                <div className="content-page" id="content-page">
                    <Outlet />
                </div>
            </div>
            <FooterStyle />
        </>
    )
}


export default Layout1;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { apiCall } from "../../../utils/apiWrapper";
import Skeleton from 'react-loading-skeleton';
import VideoPlayerModal from './video-play-modal';
import 'react-loading-skeleton/dist/skeleton.css';


const RecordingList = () => {

    const [trimmedVideoList, setTrimmedVideoList] = useState([]);
    const [trimmedVideoLoading, setTrimmedVideoLoading] = useState('');
    const [addModalShow, setAddModalShow] = useState(false);
    const [videoStreamData, setVideoStreamData] = useState({});
    const [editData, setEditData] = useState('');

    const getVideoList = () => {
        setTrimmedVideoLoading(true);
        apiCall('video/trimVideo', 'get')
            .then((res) => {
                setTrimmedVideoLoading(false);
                if (res.status === 200) {
                    setTrimmedVideoList(res?.data?.data);
                }
            })
    };

    const onClickView = (videoStreamData) => {
        setVideoStreamData(videoStreamData);
        setAddModalShow(true);
    };

    const downloadVideo = (uri) => {
        let link = document.createElement("a");
        link.download = Date.now();
        link.href = uri;
        link.click();
    }

    useEffect(() => {
        getVideoList();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Header.Title>
                                    <h4 className="card-title">Trimmed Video List</h4>
                                </Card.Header.Title>
                                {/* <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <span onClick={() => setAddModalShow(true)} className="btn btn-primary">Add Video</span>
                                </div> */}
                            </Card.Header>
                            <Card.Body>

                                {trimmedVideoLoading ? (
                                    <div>
                                        {["", "", "", "", "", "", "", ""].map((data, i) => <Skeleton key={i} baseColor="#181142" highlightColor="#241B57" className="mt-4" height={64} />)}
                                    </div>
                                ) : (
                                    (trimmedVideoList.length > 0) ? (
                                        <div className="table-view">
                                            <table className="data-tables table movie_table " style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Video</th>
                                                        <th>Name</th>
                                                        <th>Channel</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {trimmedVideoList?.map((data, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <div className="media justify-content-center align-items-center">
                                                                    <div className="iq-movie">
                                                                        <Link to="#">
                                                                            <img
                                                                                src={data?.imgSrc ? data?.imgSrc : 'https://png.pngtree.com/png-vector/20190215/ourmid/pngtree-play-video-icon-graphic-design-template-vector-png-image_530837.jpg'}
                                                                                className="img-fluid"
                                                                                style={{ height: '40px' }}
                                                                                alt=""
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media-body text-white text-left ml-3">
                                                                    <p className="mb-0">{data?.name}</p>
                                                                </div>
                                                            </td>
                                                            <td>{data?.channelName}</td>
                                                            <td>
                                                                <div className="flex align-items-center list-user-action">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                                                                        <Link onClick={() => onClickView(`https://ott-trimmed-video.s3.ap-south-1.amazonaws.com/${data?.url}`)} className="iq-bg-warning" to="#"><i className="lar la-eye"></i></Link>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
                                                                        <Link onClick={() => downloadVideo(`https://ott-trimmed-video.s3.ap-south-1.amazonaws.com/${data?.url}`)} className="iq-bg-success" to="#"><i class="las la-download"></i></Link>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>);
                                                    }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="text-center">NO DATA</div>)
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {addModalShow && <VideoPlayerModal videoUrl={videoStreamData} addModalShow={addModalShow} setAddModalShow={setAddModalShow} />}
        </>
    )
}
export default RecordingList;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { apiCall } from "../../../utils/apiWrapper";
import Skeleton from 'react-loading-skeleton';
import RecordingPlayerModal from './recording-play-modal';
import CustomPagination from '../../../components/CustomPagination';
import 'react-loading-skeleton/dist/skeleton.css';


const RecordingList = () => {

    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    const [recordingVideoLoading, setRecordingVideoLoading] = useState('');
    const [addModalShow, setAddModalShow] = useState(false);
    const [videoStreamData, setVideoStreamData] = useState({});
    const [editData, setEditData] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const getRecordingList = () => {
        setRecordingVideoLoading(true);
        apiCall(`recording/list-recording/10/${activePage}`, 'get')
            .then((res) => {
                setRecordingVideoLoading(false);
                if (res.status === 200) {
                    setTotalItems(res?.data?.totalCount);
                    setUploadedVideoList(res?.data?.data);
                }
            })
    };

    const onClickView = (videoStreamData) => {
        setVideoStreamData(videoStreamData);
        setAddModalShow(true);
    };

    useEffect(() => {
        getRecordingList();
    }, [activePage]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Header.Title>
                                    <h4 className="card-title">Recording Lists</h4>
                                </Card.Header.Title>
                                {/* <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <span onClick={() => setAddModalShow(true)} className="btn btn-primary">Add Video</span>
                                </div> */}
                            </Card.Header>
                            <Card.Body>
                                {recordingVideoLoading ? (
                                    <div>
                                        {["", "", "", "", "", "", "", ""].map((data, i) => <Skeleton key={i} baseColor="#181142" highlightColor="#241B57" className="mt-4" height={64} />)}
                                    </div>
                                ) : (
                                    (uploadedVideoList.length > 0) ? (
                                        <div className="table-view">
                                            <table className="data-tables table movie_table " style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Video</th>
                                                        <th>Channel</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Duration</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uploadedVideoList?.map((data, i) =>
                                                        <tr key={i}>
                                                            <td>
                                                                <div className="media justify-content-center align-items-center">
                                                                    <div className="iq-movie">
                                                                        <Link to="#">
                                                                            <img
                                                                                src={`https://ivs-stream-record-123.s3.ap-south-1.amazonaws.com/${data?.streamDetail?.detail?.recording_s3_key_prefix}/media/thumbnails/thumb0.jpg`}
                                                                                className="img-fluid"
                                                                                style={{ height: '80px' }}
                                                                                alt=""
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    {/* <div className="media-body text-white text-left ml-3">
                                                                    <p className="mb-0">{data?.title}</p>
                                                                </div> */}
                                                                </div>
                                                            </td>
                                                            <td>{data?.streamDetail?.detail?.channel_name}</td>
                                                            <td>{moment(data?.streamDetail?.time).format("DD MMM yyyy")}</td>
                                                            <td>{moment(data?.streamDetail?.time).format("HH:mm:ss")}</td>
                                                            <td>{moment.utc(parseInt(data?.streamDetail?.detail?.recording_duration_ms)).format("HH:mm:ss")}</td>
                                                            <td>
                                                                <div className="flex align-items-center list-user-action">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                                                                        <Link
                                                                            onClick={() =>
                                                                                onClickView({
                                                                                    videoStreamPrefix: data?.streamDetail?.detail?.recording_s3_key_prefix,
                                                                                    s3Url: data?.s3Url,
                                                                                    imgSrc: `https://ivs-stream-record-123.s3.ap-south-1.amazonaws.com/${data?.streamDetail?.detail?.recording_s3_key_prefix}/media/thumbnails/thumb0.jpg`,
                                                                                    channelName: data?.streamDetail?.detail?.channel_name
                                                                                })
                                                                            }
                                                                            className="iq-bg-warning"
                                                                            to="#"
                                                                        >
                                                                            <i className="lar la-eye" />
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                                    <Link onClick={() => onClickEdit(data)} className="iq-bg-success" to="#"><i className="ri-pencil-line"></i></Link>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                    {(videoLoading === data?._id) ? (
                                                                        <Spinner size="sm" animation="border" />
                                                                    ) : (
                                                                        <Link onClick={() => updateVideo(data?._id, { isVisible: false })} className="iq-bg-primary" to="#"><i className="ri-delete-bin-line"></i></Link>
                                                                    )}
                                                                </OverlayTrigger> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <Row className="justify-content-center">
                                                <CustomPagination activePage={activePage} setActivePage={setActivePage} totalItems={totalItems} />
                                            </Row>
                                        </div>
                                    ) : (<div className="text-center">NO DATA</div>)
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {addModalShow && <RecordingPlayerModal imgSrc={videoStreamData?.imgSrc} channelName={videoStreamData?.channelName} s3Url={videoStreamData?.s3Url} videoStreamPrefix={videoStreamData?.videoStreamPrefix} addModalShow={addModalShow} setAddModalShow={setAddModalShow} />}
        </>
    )
}
export default RecordingList;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip, Modal, Form, Button, FormCheck } from 'react-bootstrap';
import Card from '../../../components/Card';
import AddModal from './add-live';
import { apiCall } from "../../../utils/apiWrapper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ShowList = () => {

   const [streamChannelList, setStreamChannelList] = useState([]);
   const [detailModal, setDetailModal] = useState(false);
   const [channelDetail, setChannelDetail] = useState({});
   const [addModalShow, setAddModalShow] = useState(false);
   const [activeToast, setActiveToast] = useState(false);
   const [editData, setEditData] = useState('');

   const getStreamChannelList = () => {
      apiCall(`stream/getStreamListByAdminId/${localStorage.getItem('userId')}`, 'get')
         .then((res) => {
            if (res.status === 200) {
               console.log(res?.data?.data);
               setStreamChannelList(res?.data?.data);
            }
         })
   };

   // useEffect(() => {
   //    getStreamChannelList();
   // }, []);

   const onChangeStreamStatus = (streamId, checked) => {
      let newData = streamChannelList.map(data => {
         if (data?._id === streamId) {
            data.isLive = checked;
         }
         return data;
      });
      setStreamChannelList(newData);
      apiCall(`stream/${streamId}`, 'put', { isLive: checked })
         .then((res) => {
            if (res.status === 200) {

            }
         })
   };

   const onChangeStreamBannerStatus = (streamId, checked) => {
      let newData = streamChannelList.map(data => {
         if (data?._id === streamId) {
            data.isForBanner = checked;
         }
         return data;
      });
      setStreamChannelList(newData);
      apiCall(`stream/${streamId}`, 'put', { isForBanner: checked })
         .then((res) => {
            if (res.status === 200) {

            }
         })
   };

   const onClickView = (index) => {
      setChannelDetail(streamChannelList[index]);
      setDetailModal(true);
   };

   const onClickEdit = (data) => {
      setAddModalShow(true);
      setEditData(data);
   };

   const copyText = (text, toastNumber) => {
      setActiveToast(toastNumber);
      setInterval(() => {
         setActiveToast(false);
      }, 5000);
      navigator.clipboard.writeText(text).then(function () {
         console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
         console.error('Async: Could not copy text: ', err);
      });
   }

   return (
      <>
         <Container fluid>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <Card.Header.Title>
                           <h4 className="card-title">Show Lists</h4>
                        </Card.Header.Title>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                           <span onClick={() => setAddModalShow(true)} className="btn btn-primary">Add Stream</span>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        {(streamChannelList.length > 0) ? (
                           <div className="table-view">
                              <table className="data-tables table movie_table " style={{ width: "100%" }}>
                                 <thead>
                                    <tr>
                                       <th>Show</th>
                                       <th style={{ width: "60%" }}>Description</th>
                                       <th>Status</th>
                                       <th>Banner</th>
                                       <th>Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {streamChannelList?.map((data, i) =>
                                       <tr key={i}>
                                          <td>
                                             <div className="media align-items-center">
                                                <div className="iq-movie">
                                                   <Link to="#"><img src={data?.thumbnail} className="img-border-radius avatar-40 img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="media-body text-white text-left ml-3">
                                                   <p className="mb-0">{data?.title}</p>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <p className="pt-3">{data?.description}
                                             </p>
                                          </td>
                                          <td>
                                             <div className="custom-control custom-switch">
                                                <Form.Check.Input onClick={(e) => onChangeStreamStatus(data?._id, e.target.checked)} checked={data?.isLive} type="checkbox" className="custom-control-input" id={data?._id} />
                                                <Form.Check.Label className="custom-control-label" htmlFor={data?._id}></Form.Check.Label>
                                             </div>
                                          </td>
                                          <td className=''>
                                             <div className="text-center custom-control custom-switch">
                                                <Form.Check.Input onClick={(e) => onChangeStreamBannerStatus(data?._id, e.target.checked)} checked={data?.isForBanner} type="checkbox" className="custom-control-input" id={'banner' + data?._id} />
                                                <Form.Check.Label className="custom-control-label" htmlFor={'banner' + data?._id}></Form.Check.Label>
                                             </div>
                                          </td>
                                          <td>
                                             <div className="flex align-items-center list-user-action">
                                                <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                                                   <span style={{ cursor: 'pointer' }} className="mx-2 p-1 iq-bg-warning" onClick={() => onClickView(i)}><i className="lar la-eye"></i></span>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                   <span onClick={() => onClickEdit(data)} style={{ cursor: 'pointer' }} className="mx-2 p-1 iq-bg-success" to="#"><i className="ri-pencil-line"></i></span>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                   <span style={{ cursor: 'pointer' }} className="mx-2 p-1 iq-bg-primary" to="#"><i className="ri-delete-bin-line"></i></span>
                                                </OverlayTrigger>
                                             </div>
                                          </td>
                                       </tr>
                                    )}
                                 </tbody>
                              </table>
                           </div>
                        ) : (
                           <div>
                              {["", "", "", "", "", "", ""].map(() => <Skeleton baseColor="#181142" highlightColor="#241B57" className="mt-4" height={80} />)}
                           </div>
                        )}
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
            <Modal show={detailModal} style={{ margin: '100px' }} size="lg">
               <Card>
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <Card.Header.Title>
                           <h4 className="card-title">{channelDetail?.title}</h4>
                        </Card.Header.Title>
                     </Card.Header>
                     <Card.Body className="text-center">
                        <div className="mt-2">
                           Ingest Endpoint :
                           <Row className="m-0 p-0 justify-content-center">
                              rtmps://{channelDetail?.streamData?.channel?.ingestEndpoint}:443/app/
                              <span style={{ cursor: 'pointer', color: (activeToast === 'one') ? 'red' : 'white' }} onClick={() => copyText(`rtmps://${channelDetail?.streamData?.channel?.ingestEndpoint}:443/app/`, 'one')} className="ml-4">
                                 <i className="las la-copy"></i>
                              </span>
                           </Row>
                        </div>

                        <div className="mt-2">
                           KEY :
                           <Row className="m-0 p-0 justify-content-center">
                              {channelDetail?.streamData?.streamKey?.value}
                              <span style={{ cursor: 'pointer', color: (activeToast === 'two') ? 'red' : 'white' }} onClick={() => copyText(channelDetail?.streamData?.streamKey?.value, 'two')} className="ml-4">
                                 <i className="las la-copy"></i>
                              </span>
                           </Row>
                        </div>
                        <div className="mt-2">
                           URL :
                           <Row className="m-0 p-0 justify-content-center">
                              {channelDetail?.streamData?.channel?.playbackUrl}
                              <span style={{ cursor: 'pointer', color: (activeToast === 'three') ? 'red' : 'white' }} onClick={() => copyText(channelDetail?.streamData?.channel?.playbackUrl, 'three')} className="ml-4">
                                 <i className="las la-copy"></i>
                              </span>
                           </Row>
                        </div>
                        <Row className="mt-4">
                           <Form.Group className="col-12">
                              <Button type="button" onClick={() => setDetailModal(false)} variant="primary">
                                 CLOSE {' '}
                              </Button>
                           </Form.Group>
                        </Row>
                     </Card.Body>
                  </Card>
               </Card>
            </Modal>
            <AddModal setAddModalShow={setAddModalShow} addModalShow={addModalShow} getStreamChannelList={getStreamChannelList} editData={editData} />
         </Container>
      </>
   )
}
export default ShowList; 
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card'
import Chart from "react-apexcharts";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { apiCall } from "../../../utils/apiWrapper";
import "swiper/css";
import Select from 'react-select'

// img
import dash01 from '../../../assets/images/dashboard/01.jpg'
import dash02 from '../../../assets/images/dashboard/02.jpg'
import dash03 from '../../../assets/images/dashboard/03.jpg'
import dash04 from '../../../assets/images/dashboard/04.jpg'
import dash05 from '../../../assets/images/dashboard/05.jpg'
import dash06 from '../../../assets/images/dashboard/06.jpg'

import mt01 from '../../../assets/images/movie-thumb/01.jpg'
import mt04 from '../../../assets/images/movie-thumb/04.jpg'
import mt05 from '../../../assets/images/movie-thumb/05.jpg'
import mt07 from '../../../assets/images/movie-thumb/07.jpg'

SwiperCore.use([Navigation, Autoplay]);

const Dashbord = () => {
   const [videoCount, setVideoCount] = useState(false);
   const [liveCount, setLiveCount] = useState(false);
   const [categoryCount, setCategoryCount] = useState(false);
   const [tagsCount, setTagsCount] = useState(false);
   const [subCategoryCount, setSubCategoryCount] = useState(false);

   const options1 = [
      { value: 'today', label: 'Today' },
      { value: 'this month', label: 'This Month' },
      { value: 'this week', label: 'This Week' }
   ]

   const options2 = [
      { value: 'Most Likely', label: 'Most Likely' },
      { value: 'Unlikely', label: 'Unlikely' }
   ]

   const chart1 = {
      options: {
         chart: {
            id: "view-chart-01",
         },
         colors: ['#e20e02', '#f68a04', '#007aff', '#545e75'],
         labels: ["New Customer", "Exsisting Subscriber's", "Daily Visitor's", "Extented Subscriber's"],
         dataLabels: {
            enabled: false
         },
         stroke: {
            show: false,
            width: 0
         },
         legend: {
            show: false,
         },
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 200
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
      series: [44, 55, 30, 30],
   }
   const chart2 = {
      options: {
         colors: ['#e20e02', '#007aff'],
         chart: {
            id: "view-chart-03",
            foreColor: '#D1D0CF'
         },
         plotOptions: {
            bar: {
               horizontal: false,
               columnWidth: '55%',
               endingShape: 'rounded'
            },
         },
         dataLabels: {
            enabled: false
         },
         stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
         },
         xaxis: {
            categories: ['a', 'b', 'c', 'd'],
         },
         yaxis: {
            title: {
               text: ''
            }
         },
         fill: {
            opacity: 1
         },
         tooltip: {
            enabled: false,
            y: {
               formatter: function (val) {
                  return "$ " + val + " thousands"
               }
            }
         }
      },
      series: [{
         name: 'This Month',
         data: [44, 55, 30, 60]
      }, {
         name: 'Last Month',
         data: [35, 41, 20, 40]
      }],
   }
   const chart3 = {
      options: {
         chart: {
            id: "view-chart-02",
         },
         colors: ['#e20e02', '#83878a', '#007aff', '#f68a04', '#14e788', '#545e75'],
         labels: ['Actions', 'Comedy', 'Harror', 'Drama', 'Kids', 'Thrilled'],
         dataLabels: {
            enabled: false
         },
         stroke: {
            show: false,
            width: 0
         },
         legend: {
            show: false,
            formatter: function (val, opts) {
               return val + " - " + opts.w.globals.series[opts.seriesIndex]
            }
         },
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 200
               },
               legend: {
                  position: 'bottom'
               }
            }
         }]
      },
      series: [44, 30, 20, 43, 22, 20],
   }

   const getVideoList = () => {
      apiCall('video', 'get')
         .then((res) => {
            if (res.status === 200) {
               setVideoCount(res?.data?.data?.length);
            }
         })
   };

   const getStreamChannelList = () => {
      apiCall(`stream/getStreamListByAdminId/${localStorage.getItem('userId')}`, 'get')
         .then((res) => {
            if (res.status === 200) {
               console.log(res?.data?.data);
               setLiveCount(res?.data?.data?.length);
            }
         })
   };

   const getCategory = () => {
      apiCall('category', 'get')
         .then((res) => {
            if (res.status === 200) {
               setCategoryCount(res?.data?.data?.length);
            }
         })
   };

   const getTag = () => {
      apiCall('tag', 'get')
         .then((res) => {
            if (res.status === 200) {
               setTagsCount(res?.data?.data?.length);
            }
         })
   };
   const getSubCategory=()=>{
      apiCall('subcategory', 'get')
      .then((res) => {
         if (res.status === 200) {
            setSubCategoryCount(res?.data?.data?.length);
         }
      })
   };

   useEffect(() => {
      getVideoList();
      getCategory();
      getSubCategory();
      getTag();
   }, []);
//getStreamChannelList();
   return (
      <>
         <Container fluid>
            <Row>
               <Col>
                  <Row>
                     <Col sm="6" lg="6" xl="3">
                        <Card className="iq-card-block iq-card-stretch iq-card-height">
                           <Card.Body>
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="iq-cart-text text-capitalize">
                                    <p className="mb-0">
                                       Audio Count
                                    </p>
                                 </div>
                                 <div className="icon iq-icon-box-top rounded-circle bg-primary">
                                    <i className="lab la-youtube"></i>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                 <h4 className=" mb-0">
                                    {videoCount ? videoCount : (<Spinner size="sm" animation="border" />)}
                                 </h4>
                                 <Link to="/video" className="btn btn-secondary iq-waves-effect">
                                    <span>View</span>
                                 </Link>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     
                     <Col sm="6" lg="6" xl="3">
                        <Card className="iq-card-block iq-card-stretch iq-card-height">
                           <Card.Body>
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="iq-cart-text text-capitalize">
                                    <p className="mb-0 font-size-14">
                                       Category
                                    </p>
                                 </div>
                                 <div className="icon iq-icon-box-top rounded-circle bg-info">
                                    <i className="las la-stream"></i>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                 <h4 className="mb-0">{categoryCount ? categoryCount : (<Spinner size="sm" animation="border" />)}</h4>
                                 <Link to="/category" className="btn btn-secondary iq-waves-effect">
                                    <span>View</span>
                                 </Link>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col sm="6" lg="6" xl="3">
                        <Card className="iq-card-block iq-card-stretch iq-card-height">
                           <Card.Body>
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="iq-cart-text text-capitalize">
                                    <p className="mb-0 font-size-14">
                                      Sub Category
                                    </p>
                                 </div>
                                 <div className="icon iq-icon-box-top rounded-circle bg-info">
                                    <i className="las la-stream"></i>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                 <h4 className="mb-0">{subCategoryCount ? subCategoryCount : (<Spinner size="sm" animation="border" />)}</h4>
                                 <Link to="/subcategory" className="btn btn-secondary iq-waves-effect">
                                    <span>View</span>
                                 </Link>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col sm="6" lg="6" xl="3">
                        <Card className="iq-card-block iq-card-stretch iq-card-height">
                           <Card.Body>
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="iq-cart-text text-uppercase">
                                    <p className="mb-0 font-size-14">
                                       Tags
                                    </p>
                                 </div>
                                 <div className="icon iq-icon-box-top rounded-circle bg-success">
                                    <i className="las la-tags"></i>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                 <h4 className="mb-0">{tagsCount ? tagsCount : (<Spinner size="sm" animation="border" />)}</h4>
                                 <Link to="/tag" className="btn btn-secondary iq-waves-effect">
                                    <span>View</span>
                                 </Link>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </Col>
                
            </Row>
            
         </Container>
      </>
   )
}

export default Dashbord;
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Button, Form, Nav, Dropdown } from 'react-bootstrap'
import Card from '../../../Card'
import CustomToggle from '../../../dropdowns';
import { apiCall } from "../../../../utils/apiWrapper";


//img

import user1 from '../../../../assets/images/user/1.jpg'
import user01 from '../../../../assets/images/user/01.jpg'
import user02 from '../../../../assets/images/user/02.jpg'
import user03 from '../../../../assets/images/user/03.jpg'
import user04 from '../../../../assets/images/user/04.jpg'
import user05 from '../../../../assets/images/user/05.jpg'

//img
import logo from '../../../../../src/assets/images/logo.png'



const HeaderStyle1 = (props) => {

    const navigate = useNavigate();

    const minisidbar = () => {
        document.body.classList.toggle('sidebar-main')
    }

    const getStreamChannelList = () => {
        apiCall(`stream/getStreamListByAdminId/${localStorage.getItem('userId')}`, 'get')
            .then((res) => {
                if (res?.data?.isUnauthorized) {
                    localStorage.clear();
                    navigate('/auth/sign-in');
                }
            });
    };

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/auth/sign-in');
            return;
        }
        
    }, []);
    //getStreamChannelList();

    return (
        <React.Fragment>
            <div className="iq-top-navbar" style={{ zIndex: '99999', paddingTop: '10px' }}>
                <div className="iq-navbar-custom">
                    <Navbar className="p-0" expand="lg">
                        <div className="wrapper-menu" onClick={minisidbar}>
                            <div className="main-circle"><i className="las la-bars"></i></div>
                        </div>
                        <div className="iq-menu-bt d-flex align-items-center">
                            <div className="wrapper-menu" onClick={minisidbar}>
                                <div className="main-circle"><i className="las la-bars"></i></div>
                            </div>
                            <div className="iq-navbar-logo d-flex justify-content-between">
                                <Link to="#" className="header-logo">
                                    <img src={logo} className="img-fluid rounded-normal" alt="" />
                                    <div className="logo-title">
                                        <span className="text-primary text-uppercase">Audio Demo App</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="iq-search-bar ml-auto">
                            <Form action="#" className="searchbox">
                                <input type="text" className="text search-input" placeholder="Search Here..." />
                                <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                            </Form>
                        </div> */}
                        <Navbar.Toggle as={Button} aria-controls="responsive-navbar-nav">
                            <i className="ri-menu-3-line"></i>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav as="ul" className="ml-auto navbar-list iq-header">
                                {/* <Dropdown as="li" className="nav-item nav-icon search-content iq-search">
                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle iq-waves-effect text-gray rounded">
                                        <i className="ri-search-line"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Form action="#" className="search-box p-0">
                                            <input type="text" className="text search-input" placeholder="Type here to search..." />
                                            <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                        </Form>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                {/* <Dropdown as="li" className="line-height">
                                    <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle iq-waves-effect d-flex align-items-center" >
                                        <img src={'http://www.dhanwantaricolleges.com/wp-content/uploads/2020/02/flat-faces-icons-circle-man-9.png'} className="img-fluid rounded-circle mr-3" alt="user" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Item className="iq-sub-dropdown iq-user-dropdown shadow-none m-0" drop="end">
                                        <Card className="shadow-none m-0">
                                            <Card.Header className="d-flex justify-content-start bg-primary p-0">
                                                <div className="bg-primary p-3">
                                                    <h5 className="mb-0 text-white line-height">Hello {localStorage.getItem('userName')}</h5>
                                                    <span className="text-white font-size-12 iq-text">Available</span>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="d-inline-block w-100 text-center p-3">
                                                    <Link className="bg-primary iq-sign-btn" onClick={() => localStorage.clear()} to="/auth/sign-in" role="button">Sign out<i className="ri-login-box-line ml-2"></i></Link>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Dropdown.Item>
                                </Dropdown> */}
                                <Dropdown as="li" className="line-height">
                                    <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle iq-waves-effect d-flex align-items-center" >
                                        <img src={'http://www.dhanwantaricolleges.com/wp-content/uploads/2020/02/flat-faces-icons-circle-man-9.png'} className="img-fluid rounded-circle m-0" alt="user" />
                                    </Dropdown.Toggle>
                                    {/* <Dropdown.Item className="iq-sub-dropdown iq-user-dropdown shadow-none m-0" drop="end">
                                        <Card className="shadow-none m-0">
                                            <Card.Body className="p-0">
                                                <div className="bg-info p-3">
                                                    <h5 className="mb-0 text-white line-height">Hello {localStorage.getItem('userName')}</h5>
                                                    <span className="text-white font-size-12 iq-text">Available</span>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Dropdown.Item> */}
                                </Dropdown>
                                <Link data-toggle="tooltip" data-placement="top" title="logout" style={{ fontSize: '26px', color: 'white' }} onClick={() => localStorage.clear()} to="/auth/sign-in" role="button"><i className="ri-login-box-line ml-2"></i></Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div >
        </React.Fragment >
    )
}

export default HeaderStyle1

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const RecordingPlayerModal = ({ setAddModalShow, addModalShow, videoStreamPrefix, s3Url, channelName, imgSrc }) => {
    const [uploadedVideoDetail, setUploadedVideoDetail] = useState({});
    const [currentPlayer, setCurrentPlayer] = useState('');
    const [currentPlayerId, setCurrentPlayerId] = useState(`video-${Date.now()}`);
    const [form, setForm] = useState({});
    const [showTrimControl, setShowTrimControl] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [trimData, setTrimData] = useState({
        startTime: '',
        duration: '',
        fileName: '',
        customName: '',
        channelName: '',
        imgSrc: ''
    });

    const { registerIVSTech, videojs } = window;

    const intializePlayer = (url) => {
        console.log(`${currentPlayerId}`);
        // let player = videojs(`${currentPlayerId}`);
        // if (player?.src()) {
        //     player.dispose();
        // }

        // Register Amazon IVS as playback technology for Video.js
        registerIVSTech(videojs);

        let localPlayer = videojs(`${currentPlayerId}`, {
            controls: true,
            autoplay: true,
            preload: 'auto',
            techOrder: ["AmazonIVS"],
        });
        let newUrl = `https://ivs-stream-record-123.s3.ap-south-1.amazonaws.com/${videoStreamPrefix}/media/hls/master.m3u8`;
        localPlayer.src(newUrl);
        setCurrentPlayer(localPlayer);
    };

    useEffect(() => {
        intializePlayer();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCurrentPlayerId(`video-${Date.now()}`);
    }, [videoStreamPrefix]);

    useEffect(() => {
        if (s3Url) {
            let fileName = s3Url.split("/")[1];
            setTrimData({ ...trimData, fileName: fileName, channelName: channelName, imgSrc: imgSrc });
        }
    }, [s3Url]);

    useEffect((currentPlayer) => () => {
        currentPlayer?.dispose?.();
    }, []);

    useEffect(() => {
        setSuccess(false);
        setLoading(false);
    }, [addModalShow]);

    const trimVideo = () => {
        setLoading(true);
        apiCall('video/trimVideo', 'post', { ...trimData })
            .then((res) => {
                if (res.status === 200 && res.data.success) {
                    setSuccess(true);
                    setLoading(false);
                }
            })
    };

    return (
        <>
            <Container>
                <Modal size='lg' style={{ paddingTop: '100px', paddingBottom: '100px' }} show={addModalShow}>
                    <div id="instructions video" className="video-area m-4 p-4">
                        <video
                            id={`${currentPlayerId}`}
                            className="video-js vjs-default-skin vjs-fluid"
                            controls
                        >
                        </video>
                        {(showTrimControl && !success) && (
                            <Row className="justify-content-center mt-4">
                                <Form>
                                    <Row>
                                        <Col>
                                            <label className="ml-2">Start Time (s)</label>
                                            <Form.Control
                                                type="number"
                                                placeholder="start time"
                                                onChange={(e) => setTrimData({ ...trimData, startTime: e.target.value })}
                                                isInvalid={false}
                                            />
                                        </Col>
                                        <Col>
                                            <label className="ml-2">Duration (s)</label>
                                            <Form.Control
                                                type="number"
                                                placeholder="duration"
                                                onChange={(e) => setTrimData({ ...trimData, duration: e.target.value })}
                                                isInvalid={false}
                                            />
                                        </Col>
                                        <Col>
                                            <label className="ml-2">Video Name</label>
                                            <Form.Control
                                                type="text"
                                                placeholder="video name"
                                                onChange={(e) => setTrimData({ ...trimData, customName: e.target.value })}
                                                isInvalid={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Form.Group className="form-group mt-4 row justify-content-center">
                                        <Button className="mx-2" type="button" onClick={() => trimVideo()} variant=" btn-primary">
                                            {loading ? 'Trimming' : 'Submit'} {' '} {loading && <Spinner size="sm" animation="border" />}
                                        </Button>
                                        <Button className="mx-2" type="reset" onClick={() => setShowTrimControl(false)} variant=" btn-danger">Cancel</Button>
                                    </Form.Group>
                                </Form>
                            </Row>
                        )}
                        {(!showTrimControl && !success) && (
                            <Row className="justify-content-center mt-4">
                                {s3Url &&
                                    <Button className="mx-2 px-4" onClick={() => setShowTrimControl(true)} variant="success">
                                        Trim
                                    </Button>
                                }
                                <Button className="mx-2 px-4" type="reset" onClick={() => setAddModalShow(false)} variant="primary">
                                    Close
                                </Button>
                            </Row>
                        )}
                        {success && (
                            <div className="text-center">
                                <Button className="mt-4 mx-2 px-4" type="reset" onClick={() => setAddModalShow(false)} variant="primary">
                                    Close
                                </Button>
                                <p>Video converted successfully</p>
                            </div>
                        )}
                    </div>
                </Modal>
            </Container>
        </>
    )
}
export default RecordingPlayerModal;

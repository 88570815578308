import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button, Form, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from "react-redux";
import { apiCall } from "../../../utils/apiWrapper";

const mapStateToProps = (state) => {
   return {
      rtlMode: false
   };
}

const mapDispatchToProps = dispatch => ({

})


const SignIn = (props) => {
   const [show, setShow] = useState(false);
   const [form, setForm] = useState({});
   const [loginLoading, setLoginLoading] = useState(false);

   useEffect(() => {

   })

   let navigate = useNavigate();

   const onLogin = () => {
      setLoginLoading(true);
      apiCall('auth/signin', 'post', form)
         .then((res) => {
            setLoginLoading(false)
            if (res.status === 200) {
               localStorage.setItem('token', res?.data?.accessToken);
               localStorage.setItem('userId', res?.data?.id);
               localStorage.setItem('userName', res?.data?.username);
               navigate('/');
            }
         })
   };

   useEffect(() => {
      if (localStorage.getItem('token')) {
         navigate('/');
      };
   }, []);

   return (
      <>
         <section className="sign-in-page">
            <Container>
               <Row className="justify-content-center align-items-center height-self-center">
                  <Col lg="5" md="12" className="align-self-center">
                     <div className="sign-user_card ">
                        <div className="sign-in-page-data">
                           <div className="sign-in-from w-100 m-auto">
                              <div className="text-center">
                                 <img style={{ height: '150px' }} src="https://ott-assets.s3.ap-south-1.amazonaws.com/logo22.jpeg" />
                              </div>
                              <Form className="mt-4">
                                 <Form.Group>
                                    <Form.Control
                                       type="email"
                                       className="mb-0"
                                       id="exampleInputEmail1"
                                       placeholder="Enter email"
                                       onChange={(e) => setForm({ ...form, username: e.target.value })}
                                       autoComplete="off"
                                       required
                                    />
                                 </Form.Group>
                                 <Form.Group>
                                    <Form.Control
                                       type="password"
                                       className="mb-0"
                                       id="exampleInputPassword2"
                                       onChange={(e) => setForm({ ...form, password: e.target.value })}
                                       placeholder="Password"
                                       required
                                    />
                                 </Form.Group>
                                 <div className="sign-info justify-content-center mt-4 p-4">
                                    <Button type="button" onClick={() => onLogin()} variant="btn btn-primary">
                                       Sign in {' '}
                                       {loginLoading && <Spinner size="sm" animation="border" />}
                                    </Button>
                                 </div>
                              </Form>
                           </div>
                        </div>
                        <div className="mt-1 mb-4">
                           {/* <div className="d-flex justify-content-center links">
                              Don't have an account? <Link to="/auth/sign-up" className="text-primary ml-2">Sign Up</Link>
                           </div>
                           <div className="d-flex justify-content-center links">
                              <Link to="/auth/pages-recoverpw" className="f-link">Forgot your password?</Link>
                           </div> */}
                        </div>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { apiCall } from "../../../utils/apiWrapper";

const AddTag = ({ form, setForm, setAddModalShow, addModalShow, getTag, editData }) => {
    let navigate = useNavigate();
    const [error, setError] = useState({});
    const [createLoading, setCreateLoading] = useState(false);

    const onAddTag = () => {
        setCreateLoading(true);
        if (!form?.tagName) {
            setError({ tagName: 'please enter tag name' });
            return;
        }
        apiCall('tag', 'post', form)
            .then((res) => {
                if (res.status === 200) {
                    getTag();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };

    const onUpdateTag = () => {
        setCreateLoading(true);
        if (!form?.tagName) {
            setError({ tagName: 'please enter tag name' });
            return;
        }
        apiCall(`tag/${editData?._id}`, 'put', form)
            .then((res) => {
                if (res.status === 200) {
                    getTag();
                    setAddModalShow(false);
                    setCreateLoading(false);
                }
            })
    };

    useEffect(() => {
        if (form.tagName) {
            setError({ tagName: '' })
        }
    }, [form]);

    return (
        <>
            <Container fluid>
                <Modal style={{ paddingTop: '100px', paddingBottom: '100px' }} show={addModalShow}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header.Title className="row justify-content-center mt-4">
                                    <h4 className="card-title">{editData ? 'Edit' : 'Add'} Tag</h4>
                                </Card.Header.Title>
                                <Card.Body>
                                    <Row>
                                        <Col lg="12">
                                            <Form>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Name"
                                                        onChange={(e) => setForm({ ...form, tagName: e.target.value })}
                                                        isInvalid={error?.['tagName']}
                                                        value={form?.tagName ? form?.tagName : ''}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{error?.['tagName']}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control
                                                        as="textarea"
                                                        id="text"
                                                        name="text"
                                                        rows="5"
                                                        placeholder="Description"
                                                        onChange={(e) => setForm({ ...form, tagDescription: e.target.value })}
                                                        value={form?.tagDescription ? form?.tagDescription : ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="form-group mt-3 row justify-content-center">
                                                    <Button className="mx-2" type="button" onClick={() => { editData ? onUpdateTag() : onAddTag() }} variant=" btn-primary">
                                                        {editData ? 'Update' : 'Submit'}{' '}
                                                        {createLoading && <Spinner size="sm" animation="border" />}
                                                    </Button>{' '}
                                                    <Button className="mx-2" type="reset" onClick={() => setAddModalShow(false)} variant=" btn-danger">Cancel</Button>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal>
            </Container>

        </>
    )
}
export default AddTag;